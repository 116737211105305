import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Box,
  VStack,
  HStack,
  Heading,
  Text,
  Image,
  Divider,
  List,
  ListItem,
  ListIcon,
  Button,
  Flex,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  Input,
  useToast,
  ScaleFade
} from '@chakra-ui/react';
import { MdCheckCircle, MdDescription, MdArchive, MdEdit } from 'react-icons/md';
import Card from "components/Card/Card";
import Loading from 'misc/Loading';
import moment from 'moment';
import DatePicker, {registerLocale} from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import config from 'config';

import { useFetchData, usePostData } from "apiService";

function CardCitoyen(props) {

    // Datepicker
    registerLocale('fr', fr);

  // props
  const { idCitoyen, displayClearArrests = true } = props;
  const { data: citoyens } = useFetchData(`citizens/${idCitoyen}`);
  const [citoyen, setCitoyen] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { data: arrestations } = useFetchData(`citizenArrests/${idCitoyen}`);
  const { isOpen: isOpenEditModal, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure();
  const { isOpen: isOpenConfirmationModal, onOpen: onOpenConfirmationModal, onClose: onCloseConfirmationModal } = useDisclosure();
  const toast = useToast();
  const history = useHistory();
  const { postData } = usePostData();

  const [editedCitoyen, setEditedCitoyen] = useState({
    name: '',
    firstname: '',
    birthdate: '',
    address: '',
    phone: '',
    work: '',
    nationality: '',
    hair: '',
    ethnicity: '',
    sexe: '',
    sex_id: '',
    image_url: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (citoyens) {
      setCitoyen(citoyens[0]);
      setEditedCitoyen(citoyens[0]);
    }
  }, [citoyens]);

  useEffect(() => {
    if (citoyen && arrestations) {
      setIsLoading(false);
    }
  }, [citoyen, arrestations]);

  const handleEdit = () => {
    // Open modal for editing
    onOpenEditModal();
  };

  const handleEditCitoyenChange = (e) => {
    setEditedCitoyen({
      ...editedCitoyen,
      [e.target.name]: e.target.value
    });
  };

  const handleSaveEdit = async () => {
    try {
      // Send editedCitoyen to backend
      postData(`citizens/${idCitoyen}`, editedCitoyen);

      // Refetch citizen data
      onCloseEditModal();

      // Show success toast
      toast({
        title: "Citoyen modifié",
        description: "Les informations du citoyen ont été modifiées avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenArrestDetails = (arrestId) => {
    history.push(`/admin/pv/${arrestId}`);
  };

  const handleClearCriminalRecord = () => {
    onOpenConfirmationModal();
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box alignSelf="flex-start" w="full">
      <ScaleFade initialScale={0.1} in={true}>
        <VStack spacing={5} p={5} borderRadius="md">
          {/* Edit Citizen Modal */}
          <Modal isOpen={isOpenEditModal} onClose={onCloseEditModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Modifier {editedCitoyen.firstname} {editedCitoyen.name} </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>Prénom</Text>
                <Input name="firstname" value={editedCitoyen.firstname} onChange={handleEditCitoyenChange} mb={"10px"} />
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>Nom</Text>
                <Input name="name" value={editedCitoyen.name} onChange={handleEditCitoyenChange} mb={"10px"} />
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>Date de naissance</Text>
                <Box
                  padding={"10px"}
                  border={"1px solid #E2E8F0"}
                  mb={"10px"}
                  borderRadius={"5px"}
                  fontSize={"sm"}
                >
                  <DatePicker
                    selected={editedCitoyen.birthdate ? new Date(editedCitoyen.birthdate) : null}
                    dateFormat="dd/MM/yyyy"
                    locale="fr"
                    onChange={(date) => {
                      if (!date) return;
                      // On clone la date sélectionnée
                      const fixedDate = new Date(date);
                      // On force l'heure à 12h
                      fixedDate.setHours(12, 0, 0, 0);
                      setEditedCitoyen({
                        ...editedCitoyen,
                        birthdate: fixedDate,
                      });
                    }}
                  />
                </Box>
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>Adresse</Text>
                <Input name="address" value={editedCitoyen.address} onChange={handleEditCitoyenChange} mb={"10px"} />
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>Téléphone</Text>
                <Input name="phone" value={editedCitoyen.phone} onChange={handleEditCitoyenChange} mb={"10px"} />
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>Profession</Text>
                <Input name="work" value={editedCitoyen.work} onChange={handleEditCitoyenChange} mb={"10px"} />
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>Nationalité</Text>
                <Input name="nationality" value={editedCitoyen.nationality} onChange={handleEditCitoyenChange} mb={"10px"} />
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>Couleur de cheveux</Text>
                <Input name="hair" value={editedCitoyen.hair} onChange={handleEditCitoyenChange} mb={"10px"} />
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>Ethnicité</Text>
                <Input name="ethnicity" value={editedCitoyen.ethnicity} onChange={handleEditCitoyenChange} mb={"10px"} />
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>Sexe</Text>
                <Select name="sex_id" value={editedCitoyen.sex_id} onChange={handleEditCitoyenChange} mb={"10px"}>
                  <option value="1">Homme</option>
                  <option value="2">Femme</option>
                  <option value="3">Autre</option>
                </Select>
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>URL de l'image</Text>
                <Input name="image_url" value={editedCitoyen.image_url} onChange={handleEditCitoyenChange} mb={"10px"} />
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" onClick={handleSaveEdit}>Enregistrer</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Citizen Information Section */}
          <Flex flexDir={{ base: "column", md: "row" }} w="full">
            <Card>
              <HStack spacing={4} align="stretch" flex="1" pr={5} width={"200px"}>
                <Avatar borderRadius="15px" boxSize="220px" src={citoyen.image_url} alt={`${editedCitoyen.name} ${editedCitoyen.firstname}`} />
                <VStack spacing={4} align="stretch" flex="1">
                  <Heading as="h1" size="xl" color="white" whiteSpace={"nowrap"} height={"50px"}>{editedCitoyen.firstname} {editedCitoyen.name}</Heading>
                  <HStack spacing={4} align="stretch" flex="1">
                    <VStack spacing={4} align="stretch" flex="1">
                      <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Date de naissance:</b> {editedCitoyen ? moment(editedCitoyen.birthdate).format('D MMMM yyyy') : "-"}</Text>
                      <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Adresse:</b> {editedCitoyen ? editedCitoyen.address : "-"}</Text>
                      <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Téléphone:</b> {editedCitoyen ? editedCitoyen.phone : "-"}</Text>
                      <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Profession:</b> {editedCitoyen ? editedCitoyen.work : "-"}</Text>
                    </VStack>
                    <VStack spacing={4} align="stretch" flex="1" pl={150}>
                      <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Nationalité:</b> {editedCitoyen ? editedCitoyen.nationality : "-"}</Text>
                      <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Couleur de cheveux:</b> {editedCitoyen ? editedCitoyen.hair : "-"}</Text>
                      <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Ethnicité:</b> {editedCitoyen ? editedCitoyen.ethnicity : "-"}</Text>
                      <Text fontSize="md" color="white" whiteSpace={"nowrap"}><b>Sexe:</b> {editedCitoyen ? editedCitoyen.sexe : "-"}</Text>
                    </VStack>
                  </HStack>
                </VStack>
              </HStack>

              {/* Actions Section */}
              {config.role("worker") && (
              <HStack spacing={4} align="stretch" w="full" justifyContent="flex-end">
                <Button colorScheme="blue" onClick={handleEdit}><MdEdit color="white" size={24} /></Button>
              </HStack>
              )}
            </Card>
          </Flex>
        </VStack>
      </ScaleFade>
    </Box>

  );
}

export default CardCitoyen;
